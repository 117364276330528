* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: red;
}


.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
}

i.fa.fa-users {
  margin-right: 9px;
  color: #6B24D6;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #474646;
  -webkit-font-smoothing: antialiased;
  font-family: 'Raleway' !important;
  background-size: cover;
}

.logo-top h1,
.logo h1 {
  color: #ffffff;
  font-size: 57px;
  font-weight: bold;
  font-family: monospace;
}

.logo-top h1,
.logo h1 {
  font-size: 35px;
}

.login-area {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #1A003D;
}

.chamge-text {
  font-size: 35px !important;
}

.change-section .login-content-area {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28%;
}

.dashboard-area {
  width: 100%;
  margin: 19px 0 0 0;
}

.admin-header,
.footer-content {
  min-width: 87%;
  margin-left: 244px;
}

.login-content-area {
  border-radius: 50px;
  padding: 50px 70px;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border: 1px solid #1A003D;
}

.logo-top {
  text-align: center;
  margin-bottom: 50px;
}

.login-content-area h2 {
  color: #1a003d;
  font-size: 55px;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
}

.login-content-area form {
  margin: 22px 0 0 0;
}

.login-content-area label.form-label {
  color: #1a003d !important;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 3px;
}

.login-content-area p {
  font-size: 17px;
  color: #000;
  text-align: center;
}

.login-content-area .form-control {
  border: 1px solid #00000017 !important;
  padding: 12px;
  color: #000 !important;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 50px;
  -webkit-text-fill-color: #000 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
}

.table-responsive {
  min-height: 550px;
}

.login-content-area .form-control:focus {
  color: #fff;
  background-color: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

.login-content-area button {
  color: #fff;
  padding: 10px 45px;
  font-weight: 600;
  font-size: 17px;
  width: 35%;
  margin: 0 auto;
  display: block;
  margin-top: 42px;
  border-radius: 10px;
  background: var(--dark-blue, #1A003D) !important;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1a003d !important;
  border-color: #1a003d !important;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1a003d !important;
  border-color: #1a003d !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #1a003d;
  border-color: #1a003d;
  box-shadow: unset !important;
}

.btn:hover {
  color: #ffffff !important;
}

.pagination li {
  padding: 14px;
  color: #fff;
}

.swal-modal {
  border-radius: 19px;
  min-width: 415px;
  width: 415px;
}

button.user-back-btn a i {
  margin-right: 7px;
  font-size: 23px;
}

button.user-back-btn a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

button.user-back-btn {
  margin-left: 70px;
  margin-bottom: 30px;
}

.swal-title {
  color: rgb(0 0 0);
}

.pagination {
  margin-top: 14px;
}

.swal-text {
  color: rgb(0 0 0);
  font-size: 18px;
  margin-bottom: 10px;
}

.swal-footer {
  text-align: center;
}

.pagination .disabled {
  opacity: 0.5;
}

.pagination .active a {
  color: #fff !important;
  border: 1px solid;
  padding: 10px;
}

.swal-button {
  background-color: #1A003D;
  border-radius: 10px;
  padding: 9px 40px;
  font-size: 16px;
}

.swal-button:not([disabled]):hover {
  background-color: #1A003D;
}

.swal-button:focus {
  box-shadow: unset;
}

.swal-button--cancel {
  background: #000 !important;
  color: #fff;
}

.pagination li a {
  color: #fff;
  text-decoration: none;
}

.content {
  padding-left: 270px;
  padding-right: 0;
}

.profile-image img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.mian_content {
  background: #fff;
}

.sidebar {
  width: 250px;
  padding: 0 10px;
  position: fixed;
  height: 100vh;
  top: 0;
  transition: 1s all;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #1A003D;
  border-right: 1px solid #1A003D;
  overflow: auto;
}

.footer-content {
  padding: 12px;
}

.sidebar_option {
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.sidebar_option img {
  margin-right: 9px;
  width: 20px;
  filter: brightness(0) invert(1);
}

a.sidebar_option.active img {
  filter: unset;

}

.table td a i {
  color: #fff;
}

.user-info-area-section {
  width: 77%;
}

/* .sidebar_option img {
  filter: brightness(0);
} */
.logo {
  text-align: center;
  padding: 22px 0 70px;
}

.sidebar_option:hover {
  color: #ffffff;
  font-weight: 500;
}

.barbtn img {
  filter: brightness(0) invert(1);
  width: 32px;
  margin-left: 12px;
}


.dashboard-top-filter-area {
  justify-content: flex-end;
  align-items: end;
}

.content.content-home {
  padding-left: 0 !important;
}

.barbtn {
  background: none;
  padding-left: 15px;
  transition: 1s all;
  position: relative;
  top: 21px;
  left: 13%;
  z-index: 999;
}

.mian_content .content {
  padding-left: 0;
  transition: 1s all;
}

.mian_content.active .content {
  padding-left: 240px;
  transition: 1s all;
}

.mian_content.active .barbtn {
  padding-left: 255px;
  transition: 1s all;
}

.footer-content p {
  color: #000 !important;
  text-align: center;
  font-weight: 600;
}

.custom_height {
  min-height: 773px;
}

.heading-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.add-subscription-btn {
  background: #edd07d;
  border: none;
  color: #000;
}

.userlist-table table {
  background: white;
  border-radius: 15px;
  overflow: hidden;
}

.userlist-table table th {
  padding: 12px 25px !important;
  max-width: 300px;
}

.userlist-table table td {
  padding: 10px 25px;
  max-width: 400px;
}

.user-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  min-width: 60px;
  object-fit: cover;
}

.active-status {
  color: #ffc41b;
}

.status-box {
  display: flex;
  align-items: center;
}

.status-box i {
  background: #edd07d;
  padding: 4px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.mian_content .sidebar {
  left: -270px;
  transition: 1s all;
}

.mian_content.active .sidebar {
  left: 0;
  transition: 1s all;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 10px 0;
}

.admin-header {
  padding: 10px;
  padding-right: 0;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border-bottom: 1px solid #1A003D;
}

.mian_content.active .admin-header {
  margin-left: 240px;
  transition: 1s all;
}

.logout-btn button {
  background: none !important;
  border: none !important;
}

.logout-btn button img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.logout-btn .dropdown-menu.show {
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
}

.logout-btn .dropdown-item {
  color: #000;
  font-weight: 600;
}

.logout-btn .dropdown-item i {
  margin-right: 12px;
}

.logout-btn s.dropdown-item:focus,
.dropdown-item:hover {
  color: #000;
  background-color: transparent;
}

.dashboad-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 5px;
  color: #000;
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 10%) !important;
}

.dashboard-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-left h3 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.solo-template-switch .form-check-input:checked {
  background-color: #fd3c74 !important;
  border-color: #fd3c74 !important;
}

.dashboard-right img {
  width: 60px;
  height: 60px;
}

.dashboad-table table tr {
  border-bottom: 1px solid #ffffff08;
}

.dashboad-table {
  margin-top: 30px;
}

.dashboad-table table thead {
  border-bottom: 1px solid #ffffff08;
  color: #000;
}

.dashboad-table table th,
td {
  padding: 13px !important;
  word-break: break-all;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  vertical-align: -webkit-baseline-middle;
}

.icons-actions i {
  color: #fff;
}

.system-log-area-date-picker input:disabled {
  color: #fff !important;
}

.sidebar_option.active {
  color: #1a003d;
  background: #fff;
  font-weight: 600;
  border-radius: 5px;
  padding: 12px 7px;
}

.user-management {
  margin-top: 0;
}

.search-area input:focus {
  background-color: black;
  box-shadow: none;
  color: white;
  border: 1px solid #a8a8a8;
}



.user-toggle .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.user-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

th {
  white-space: nowrap;
}

.transaction-dashboard {
  margin-top: 15px;
}

/* Rounded sliders */
.user-toggle .slider.round {
  border-radius: 34px;
}

.user-toggle .slider.round:before {
  border-radius: 50%;
}

/* toggle-end */
.dashboard-area-heading h2 {
  color: #1A003D;
  margin-bottom: 22px;
  font-size: 33px;
  font-weight: 800;
}

.audit-request-box .table-responsive-sm {
  padding: 12px 21px;
  border-radius: 20px;
  overflow: auto;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #1A003D;
  border: 1px solid #1A003D;
}

.audit-request-box tr {
  vertical-align: middle;
  /* border-bottom: 1px solid #e1e1e133; */
  color: #fff;
}

.audit-request-box thead {
  border-bottom: 1px solid #e1e1e133;
}

.add-btn button i {
  margin-right: 10px;
}

.mian_content.active .footer-content {
  margin-left: 240px;
  transition: 1s all;
  padding: 20px;
  background: #d7e7f9;
}

.header-dropdown-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-btn.view-btn button i {
  margin-left: 10px;
}

.export-btn.export-btn-nft {
  width: auto;
  margin: 0 10px;
}

.user-info p span {
  color: #fff;
  font-size: 18px;
}

.colored-text {
  color: #086ad8;
}

.user-content-area table {
  background: none;
  box-shadow: none;
}

.management-area-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0;
  /* overflow: auto; */
}

.management-area-top .filter-data .css-b62m3t-container {
  width: 100%;
}

.user-management.filter-data {
  width: 37%;
  padding: 0 5px;
}

.commission h6 {
  color: #1a003d;
}

.mt-3.profilr-update button {
  background: #1a003d !important;
  color: #ffffff;
  font-weight: 700;
}

.commission span {
  color: #1a003d !important;
  font-weight: 600;
}

.commission {
  border: 1px solid #1A003D;
  border-radius: 20px;
}

.commission .rounded-circle {
  width: 200px;
  height: 200px;
  border: 1px solid #00000029 !important;
}

.editprofile {
  border-radius: 7px !important;
}

.editprofile:focus {
  box-shadow: none !important;
  border: 1px solid #00000029 !important;
}

.profilr-update Button {
  background-color: #6B24D6;
  border: none;

}

.commission Form {
  padding: 12px 21px;
}

.userInfo-back {
  background-color: #6B24D6 !important;
  border-color: #6B24D6 !important;
}

.commission-button {
  background: #1A003D !important;
  color: white !important;
  border: #1A003D !important;
  padding: 10px 25px !important;
}

.commission .rounded:focus {
  box-shadow: none;
  border-color: #00000029;
}

.primaryclient:hover {
  color: #5d6262 !important;
}

/* .select-image{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
} */
.profileImage-select {
  height: 200px;
  position: absolute;
  top: 17%;
  left: 14%;
  width: 200px;
  opacity: 0;
}

.user-searchbar.search-data {
  width: 33%;
}

.export-btn {
  width: 30%;
}

.export-btn button {
  border: 1px solid #fff;
  color: #fff !important;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
}

.export-btn i {
  background: none !important;
  font-size: 12px !important;
  margin-right: 5px !important;
  padding: 0 !important;
}

.user-profile img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #fff;
}

.pagination-user-management {
  padding-bottom: 15px;
}

.user-management-right-area.mt-0 {
  background-color: #dbdbdb;
  border-radius: 5px;
  min-height: 460px;
}

.user-management-right-area table {
  background: none;
  box-shadow: none;
}

.dashboard-area-top {
  font-size: 26px;
  font-weight: 600;
}

.user-searchbar form {
  width: 164px;
  position: relative;
}

.swal2-html-container {
  color: #000 !important;
}

.list-data {
  cursor: pointer;

}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000da;
  top: 0;
  z-index: 9999;
  left: 0;
}

h2#swal2-title {
  color: #000;
  font-size: 28px;
  font-weight: 500;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.25em;
  color: #fff;
  font-size: 1em;
  background: #35a9ef !important;
  border: #35a9ef !important;
}

.form-select:focus {
  border-color: #d1b9ab !important;
  outline: 0;
  box-shadow: none !important;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginPageLoader div {
  left: 50% !important;
}

.user-searchbar form .form-control {
  background: none;
  border: 1px solid #fff;
  padding-right: 40px;
}

td.action-area i {
  color: #6B24D6;
  border: 1px solid #6B24D6;
  padding: 6px;
  border-radius: 4px;
  margin: 0 4px;
}

.user-searchbar form .form-control::placeholder {
  color: #ffff;
  font-size: 13px;
}

.user-searchbar form button .btn-check:focus+.btn,
.btn:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

.nft-area-top {
  justify-content: end;
  margin-bottom: 15px;
}

.nft-area-top .export-btn button {
  margin-right: 10px;
}

.nft-pitcures img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 5px;
}

tr {
  vertical-align: middle;
}

.sub-admin-content-area {
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  overflow: hidden;
}

.sub-admin-content-area h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  padding: 8px 14px;
  font-weight: 600;
}

.subadmin-search-area {
  padding: 15px 15px;
}

.subadmin-search-area form {
  width: 100% !important;
}

.subadmin-search-area form i {
  right: 0px;
  bottom: 0px;
  padding: 11px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
}

.create-users-form-area .form-label {
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
}

.create-users-form-area .form-control {
  background: none;
  border: 1px solid #999;
  color: #fff;
}

.create-users-form-area .form-control::placeholder {
  font-size: 14px;
  color: #999;
}

.sub-admin-content-area p {
  color: #fff;
  background: #d6cfff14;
  padding: 9px 15px;
  font-size: 15px;
}

.user-setting-area th {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.add-image-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.file-input {
  position: absolute;
  top: 0px;
  z-index: 1;
  font-size: 0;
  height: 38px;
  cursor: pointer;
  opacity: 0;
}

.user-list-admin h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 14px;
}

.otp-area {
  justify-content: center;
  padding: 45px 0;
}

.otp-area input {
  border: 1px solid hsla(0, 0%, 100%, 0.5490196078431373) !important;
  border-radius: 5px;
  padding: 12px;
  color: #fff !important;
  background-color: #1f1422 !important;
  width: 45px !important;
  height: 45px !important;
}

button.verify-otp-btn {
  padding: 8px 24px;
  margin-top: 10px;
}

tr.user-setting-area {
  background: #2f2334;
}

.sub-admin-content-area td {
  color: #fff;
}

.sub-admin-content-area table tr {
  border-bottom: 1px solid #9b9b9b14;
}

.subadmin-area {
  margin-bottom: 30px;
}

.system-log-area-date-picker input {
  margin-right: 10px;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 168px;
}

.user-update-box {
  display: flex;
  justify-content: space-between;
  background: #fd7f1117;
}

.user-update-box h5 {
  background: none;
}

.system-log-flex {
  justify-content: space-between;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.details-name h6 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.details-name p {
  font-size: 15px;
  color: #b9b9b9;
}

.details-name {
  margin-top: 10px;
}

.user-profile-detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-profile-detail img {
  margin-right: 10px;
}

.detail-area-two {
  display: block;
}

.detail-area-two .user-info {
  display: flex;
  justify-content: space-between;
}

.detail-area-two .user-info p {
  width: 25%;
  font-size: 14px;
  padding: 10px 4px 0 6px;
  text-align: left;
  border-right: 1px solid #484848;
}

.detail-area-two .user-info p:first-child {
  padding-left: 0;
}

.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.detail-area-two .user-info p:last-child {
  border: none;
}

.detail-area-two .user-info p span {
  font-size: 14px;
}

.not-data-found {
  text-align: center;
}

.not-found {
  text-align: center;
  color: #fff;
  font-size: 26px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction-area {
  margin-top: 0;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  color: #fff;
  min-height: 610px;
}

.transaction-area table {
  background-color: unset !important;
  box-shadow: none;
  border-radius: 10px;
  color: #fff;
  overflow: hidden;
}

.transaction-area .export-btn button {
  display: block;
  margin-left: auto;
}

.transaction-area .export-btn {
  padding: 15px 15px 0 0;
}

.err-msg {
  color: red;
}

.system-log-area-date-picker label {
  color: #fff;
}

.management-export {
  max-width: 150px;
}

/* width */
::-webkit-scrollbar {
  width: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #61616175;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-info p,
.user-info p span {
  word-break: break-all;
}

.audit-buttons button {
  color: #000;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 400;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #000;
  background: transparent;
  box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%);
}

.audit-buttons {
  text-align: right;
}

.description-box {
  white-space: pre-wrap;
  max-width: 400px;
  /* width: 400px; */
}

.dashboard-main-area {
  margin-top: 15px;
  min-height: calc(100vh - 152px);
  margin-left: 230px;
  position: relative;
}

.no-found {
  width: 580px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  margin-left: 22px;
}

.card-img-view img {
  width: 85px;
  height: auto;
}

.upload-area i {
  font-size: 120px;
  color: #d0d0d0;
  justify-content: center;
  display: flex;
}

.upload-area input {
  width: 120px;
  height: 120px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: -1;
}

.upload-file-modal .modal-footer {
  justify-content: center;
  border-top: none;
}

.upload-file-modal .modal-header {
  border-bottom: none;
}

.upload-file-modal .modal-title {
  color: #000;
  font-weight: 600;
}

.close-btn.btn.btn-secondary {
  background: #676767 !important;
  border: none;
}

.modal-title {
  color: #180848;
  font-weight: 800;
}

.modal-footer button {
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 10px !important;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #1A003D;
  background: #1A003D;
  box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%);
  border-radius: 50px;
}

.btn-close {
  opacity: 1;
}

.icons-actions .btn {
  background: #0a6bd7;
  padding: 5px 11px;
  font-size: 13px;
  border: 0;
  color: #fff !important;
  margin: 4px 0 0 0;
  width: 70%;
}

.no-dat-found-box img {
  text-align: center;
  display: block;
  margin: 20px auto;
  width: 292px;
  border-radius: 22px;
  opacity: 0.6;
}

.no-dat-found-box h3 {
  font-size: 28px;
  font-weight: 700;
  color: #fff !important;
}

.outer-form {
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 8%) !important;
  padding: 18px 40px;
  border: 1px solid #dfdfdf;
}

.outer-form label {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.outer-form input {
  font-family: "Montserrat", sans-serif;
  padding: 8px;
  font-size: 16px;
}

.outer-form h5 {
  font-size: 23px;
  margin: 22px 0;
  color: #000;
  font-weight: 600;
  text-transform: unset;
}

.outer-form h6 {
  font-weight: 600;
  color: #000;
  font-size: 17px;
}

.vulnerability-box .inner-box {
  padding: 22px;
  background: #f2efef;
  margin: 1px 0;
}

.file-upload {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.file-upload input {
  opacity: 0;
  width: 82px;
  height: 82px;
}

.file-upload .upload-img {
  position: absolute;
  top: 0;
  background: #fff;
  padding: 8px;
  border-radius: 10px;
  pointer-events: none;
  cursor: pointer;
  width: 82px;
  height: 82px;
}

.img-view {
  width: 82px;
  height: 82px;
  background: #fff;
  margin: 0 0 13px 12px;
  border-radius: 11px;
  position: relative;
}

.img-view img {
  width: 100%;
  object-fit: cover;
}

.add-more img {
  width: 14px;
  margin: 0 12px 0 0;
}

.vulnerability-boxes {
  background: #f2efeff5;
  padding: 13px 21px;
  border: 1px solid #f2efeff5;
  margin: 12px 0 12px 0;
}

input[type="radio"] {
  appearance: none !important;
  border: 1px solid #d3d3d3 !important;
  width: 25px;
  height: 25px;
  content: none !important;
  outline: none !important;
  margin: 0 9px 0 0;
  border-radius: 2px !important;
}

input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

.form-check-input:checked[type="radio"] {
  background-image: none;
  position: relative;
}

input[type="radio"]:checked::before {
  position: absolute;
  color: #fff !important;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
  font-size: 17px;
  top: -2px;
  left: 1px;
}

.form-check-input:focus,
.form-select:focus {
  box-shadow: unset;
}

.dashboard-main-area label {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.dashboard-main-area .form-select,
.dashboard-main-area .form-control {
  font-size: 13px;
  font-weight: 600;
  padding: 9px;
  background: none;
  color: #000;
  border: 1px solid #00000029;
  border-radius: 10px;
  width: 200px;
  min-width: 200px;
  background: #fff;
  padding-right: 40px;
}

.audit-buttons.inner-table button {
  padding: 6px 10px;
  font-size: 13px !important;
  width: unset;
  margin: 4px;
  width: 70%;
}

.audit-buttons.inner-table {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-cards {
  padding: 22px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  display: flex;
  height: 100%;
  color: #fff;
  background: #fff;
  border: 1px solid #1A003D;
}

.card-content h3 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

td.action-area button {
  background: #6B24D6;
  color: #fff;
  padding: 2px 15px;
  font-size: 14px;
  margin: 0 5px;
}

button.reject-btn.btn.btn-unset {
  background: none;
  border: 1px solid #6B24D6;
  color: #6B24D6;
  padding: 2px 20px;
}

.copy-clipboard i {
  color: #6B24D6;
}

.form-check-input:checked {
  background-color: #1a003d !important;
}

.form-switch .form-check-input {
  background-color: #fff;
  border: 1px solid #00000017;
  width: 35px !important;
  height: 17px;
}

.text-success {
  color: #00a226 !important;
}

.text-danger {
  color: #f52727 !important;
}

td.copy-clipboard a {
  color: #fff !important;

}

i.fa.fa-clipboard {
  color: #fff;
  margin-left: 9px;
}

.card-content h2 {
  font-size: 26px;
  font-weight: 800;
  color: #1a003d;
  margin-top: 12px;
  margin-right: 67px;
}

.search-area {
  position: relative;
}

.search-area .fa {
  position: absolute;
  top: 35px;
  right: 20px;
  color: #000000;
}

.fa-close {
  color: #000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1A003D;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1A003D;
}

.main-heading {
  font-size: 32px;
  margin: 31px 0 0 0;
  color: #1A003D;
  font-weight: 700;
}

/* .filter-select {
  margin-top: 36px;
} */

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.custom-shimmer {
  background: linear-gradient(to right, #ddd8d8 8%, #fdfdfd 18%, #dddddd3b 33%);
  background-size: 1000px 100%;
  animation: shimmer 3.2s linear infinite forwards;
}

.shimmer-thumbnail {
  min-width: 80px;
  width: 82%;
  height: 82px !important;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 12px;
  margin: 0 0 0 12px;
}

.img-view img {
  width: 100%;
  height: 82px;
  object-fit: contain;
  padding: 10px;
}

.img-view .fa-close {
  position: absolute;
  right: -4px;
  top: -4px;
  background: #ff0707;
  padding: 2px;
  border-radius: 22px;
  color: #fff !important;
  font-size: 11px;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard-cards a {
  text-decoration: none;
}

.contact-search {
  top: 47px !important;
}

.view-btn {
  background: #086ad7;
  padding: 6px 10px;
  border-radius: 5px;
  color: #fff;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

p.view-all-btn {
  text-align: end;
  color: #6B24D6;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.note_area {
  height: 182px;
  overflow: auto;
  font-size: 14px !important;
}

.table td a {

  color: #fff;
  text-decoration: underline;

}

.table td a:hover {
  color: #fff;
}

.table .wholesaler a:hover {
  color: #000 !important;
}

/* .table td a:hover {
  color: #086ad7 !important;
} */

.img-view.top-logo {
  background: #f2f0f0;
  margin: 0;
}

.btn-view a {
  text-decoration: none !important;
  outline: none;
}

.no-found h2 {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0;
  color: #000;
}

.no-found p {
  color: #fff;
}

.login-content-area .spinner-border {
  color: #fff !important;
}

.user-info-area {
  padding: 12px 21px;
  border-radius: 20px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  margin-left: 55px;
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  color: #000;
  background: #fff !important;
  border: 1px solid #1A003D;
}

.user-info-area-section h6 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  padding: 13px 0;
}

.user-info-area-section p {
  color: #6B24D6ff;
  font-size: 17px;
  font-weight: 300;
  padding: 10px 0;
  font-weight: 400;
}

.wallet-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back-btn {
  margin-left: 55px;
  margin-top: 3px;
  background-color: #6B24D6;
  border-color: #6B24D6
}

.arrow-down-area {
  position: relative;
}

.arrow-down-area i {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #000;
}

.edit_profile .form-control {
  color: #000 !important;
}

.fa-edit {
  color: #6B24D6;
}

.order-detail-content-area {
  background: none !important;
  border: none !important;
  box-shadow: none;
  overflow: unset;
}

.order-detail-left-area .table>:not(caption)>*>* {
  background-color: unset;

}

.order-detail-left-area p {
  color: #000;

}

.order-detail-left-area {
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  color: #fff;
  background: #fff;
  border: 1px solid #1A003D;
  border-radius: 20px;
  overflow: hidden;
}

.order-detail-left-area h4 {
  border-bottom: 1px solid #00000026;
  padding: 15px;
  color: #000;
  margin-bottom: 0;
}

.location-address-area .table thead th {
  padding: 8px 0;
}

.location-address-area .table thead {
  background: #fdece2;
}

img.order-detail-product-image {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border: 1px solid #0000002b;
  background: #0000002b;
  border-radius: 10px;
}

td.product-image-box {
  width: 110px;
}

td.order-product-detail-area p span {
  color: #1A003D;
  font-weight: 800;
}

td.order-product-detail-area p {
  font-size: 13px;
  padding-left: 0;
  margin-bottom: 0;
}

button.tab-left-arrow-btn,
button.tab-right-arrow-btn {
  align-items: center;
  border-radius: 50%;
  bottom: 16px;
  box-shadow: 0 4px 33px 10px rgba(0, 0, 0, .19);
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  width: 30px;
  z-index: 1;
}

.product-image-left button.tab-left-arrow-btn,
.product-image-left button.tab-right-arrow-btn {
  top: 50%;
}

button.tab-right-arrow-btn {
  background: #1A003D;
  right: 8px;
}

button.tab-left-arrow-btn {
  background: #fbdfce;
  left: 8px;
}

.product-image-left {
  position: relative;
}

:root,
.css-d7l1ni-option:active {
  --swiper-theme-color: #1A003D !important;
}

.errors {
  color: red;
}

.location-address-area p {
  font-size: 13px;
  padding: 0 12px;
}

td.box-width {
  width: 100px;
}

td.order-product-detail-area {
  padding-left: 0 !important;
  vertical-align: middle;
}

td.order-product-detail-area h6 {
  font-weight: 700;
  text-transform: capitalize;
}

.capitalized{
  text-transform: capitalize;
}
.product-description-area {
  color: #000;
}

.product-description-area h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-description-area p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.40);
  margin-bottom: 22px;

}

td.eye-toggle i {
  margin-left: 7px;
}

td.eye-toggle {
  display: flex;
  align-items: center;
}

.load-management-menu.collapse.show,
.load-management-menu.collapse,
.load-management-menu.collapsing {
  padding-left: 35px;
}

.load-management-menu.collapse.show a {
  padding-bottom: 5px !important;
}

.search-area input:focus {
  background-color: #f8d8cb00;
  color: #000;

}

.truck-detail-box .modal-body ul {
  padding-left: 0;
}

.truck-detail-box .modal-body ul li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.truck-detail-box .modal-header {
  border-bottom: none;
  padding-bottom: 9px;
}

.truck-detail-box .modal-footer {
  border-top: unset;
  padding-top: 0;
}

.truck-detail-box .modal-body {
  padding-bottom: 0;
}

.truck-detail-box .modal-body ul li span {
  color: gray;
}

table.UserListTable.table td ul li span {
  font-size: 15px;
  font-weight: 900;
}

.UserListTable tbody tr td.loads-view,
.UserListTable tbody tr td span.loads-view {
  color: #1A003D !important;
  text-decoration: underline;
  cursor: pointer;
}

.heading-top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.heading-top-area-right {
  display: flex;
  align-items: end;
  flex-wrap: wrap;
}

.user-type-top {
  margin: 0 10px;
}

.export-csv-btn button:hover {
  background: #1A003D !important;
  color: #fff !important;
}

.profile-top-action-btn.form-switch {
  display: flex;
  justify-content: end;
}

.profile-top-action-btn.form-switch .form-check-input {
  background-color: #e8cbb8;
  border: 1px solid #00000017;
  width: 55px !important;
  height: 27px;
}

p.user_detail.id-preview-icon {
  margin-left: 16px;
}

p.user_detail.id-preview-icon i {
  color: #6B24D6 !important;
  text-align: center;
  padding-left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.options_data {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 22px;
}

tbody tr:first-child td:first-child {
  width: unset !important;
}

.email-section {
  width: 21%;
}

.availabilit-section {
  background: #1a003d;
  padding: 15px;
  border: 1px solid #1A003D;
  border-radius: 20px;
  overflow: auto;
}

button.btn.btn-success {
  font-size: 13px;
  background: #704bc5;
  border: none;
  border-radius: 22px;
  padding: 6px 18px;
}

.logo-top img {
  width: 230px;
}

.add-pediction-area input {
  border: 1px solid #00000017 !important;
  padding: 12px;
  color: #000 !important;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 40px;
  -webkit-text-fill-color: #000 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
}

.add-pediction-area textarea,
.add-pediction-area select.form-control {
  border: 1px solid #00000017 !important;
  padding: 12px;
  color: #000 !important;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  -webkit-text-fill-color: #000 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
}

.add-pediction-area label.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #1a003d;
  margin-bottom: 0px;
}

p.add-option i {
  background: #1a003d;
  color: #fff !important;
  font-weight: 400;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 14px;
  width: 26px;
  height: 26px;
  min-height: 26px;
  min-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  cursor: pointer;
}

p.add-option {
  margin-bottom: 0;
}

div#contained-modal-title-vcenter {
  font-size: 20px;
  font-weight: 800;
  color: #1a003d;
}

.form-control:focus {
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10) !important;
}

.upload-image-area {
  background: #8080804f;
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.upload-image-area img {
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 10px;
  border: 1px solid #4b4b4b24;
}

.upload-image-area-right {
  position: relative;
}

.common-image-area {
  display: flex;
}

.upload-image-area-right img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

© {
  position: relative;
  text-align: center;
}

.choose-image-area {
  margin: 3px 0 10px 0;
  position: relative;
}

td.msg-contact {
  white-space: break-spaces;
  width: 30%;
}

.choose-image-area button {
  background: #1A003D;
  color: #fff;
  font-size: 11px;
  padding: 2px 7px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.upload-image-area-spacing {
  text-align: center;
  margin: 0 0 0 8px;
}

.choose-image-area input {
  position: absolute !important;
  width: 100px !important;
  height: 26px !important;
  min-height: 26px !important;
  left: 0;
  opacity: 0;
}

.add-pediction-area label.form-label span {
  color: red;
  font-size: 18px;
}

.upload-image-area input {
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  opacity: 0;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0;
}

.upload-image-area i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #808080b5;
  z-index: 1;
}

.upload-image-area-right i {
  position: absolute;
  top: -9px;
  right: -5px;
  left: unset;
  transform: unset;
  background: red;
  color: #fff;
  width: 28px;
  height: 28px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

ul.prediction-tab {
  border-bottom: none;
}

ul.prediction-tab.nav-tabs .nav-link {
  background: #fff;
  border: 2px solid #1a003d !important;
  color: #1a003d;
  font-weight: 700;
  padding: 6px 15px;
  border-radius: 8px !important;
  margin: 0 5px 6px 0;
}

ul.prediction-tab.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #1a003d !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.view-eye-area i {
  margin: 0 10px 0 0;
}

.view-eye-area {
  display: flex;
  align-items: center;
}

.common-padding {
  padding: 10px 0;
  min-height: 770px;
  height: 100%;
}

.prediction-detail-area .heading-top-area h2 {
  color: #1a003d;
  font-weight: 800;
  padding-bottom: 20px;
}

.prediction-detail-left-content h5 {
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  margin: 10px 0 5px;
  line-height: 35px;
}
.prediction-detail-left-content p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 24px;
}

.prediction-detail-right {
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
  background: #fff;
  padding: 0 30px 30px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.prediction-box-outer {
  position: relative;
}

.prediction-detail-right-top-heading h6 {
  font-size: 25px;
  color: #1a003d;
  font-weight: 700;
  margin-bottom: 0;
}

p.progress-total {
  color: #1a003d;
  font-weight: 700;
  margin: 10px 0;
}

p.progress-total span {
  color: #a550d8;
  font-weight: 500;
}

.betting-window h6 {
  font-size: 17px;
  color: #1a003d;
  font-weight: 600;
  margin-bottom: 8px;
}

.betting-window-content {
  border-radius: 10px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
  padding: 12px;
}

.start-end-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-end-date p {
  color: #FE3E75;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.start-date-info p,
.end-date-info p {
  color: #494040;
  font-weight: 500;
}

.betting-window {
  margin-top: 20px;
}

.bg-main-area {
  background: #1a003d;
  padding: 20px 10px;
  border-radius: 20px;
}

.poll-listing p {
  background: #2196F3;
}

.quiz-listing p {
  background: #a550d8;
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  border-radius: 5px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-listing {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  top: -10px;
  right: 30px;
  z-index: 99;
}

.prediction-time-top-area {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: -8px;
  z-index: 99;
  left: 80px;
}

.prediction-detail-right-top-heading h6 i {
  font-size: 17px;
  cursor: pointer;
}

table.UserListTable.table th i {
  font-size: 15px;
  cursor: pointer;
}

.prediction-time-top-area p {
  position: absolute;
  top: 33%;
  left: 50%;
  font-size: 13px;
  color: #fff;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.prediction-detail-image img {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #ffffff82;
}

.prediction-detail-image {
  margin-bottom: 20px;
}

.prediction-detail-right-top-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0 5px;
}

.prediction-detail-right-top-heading p {
  background: #FE3E75;
  font-size: 14px;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-weight: 500;
}

.top-back-btn button i {
  margin-right: 2px;
}

.top-back-btn button {
  background: #1a003d;
  color: #fff;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.filter-top-area .css-b62m3t-container {
  width: 110px;
}

.login-content-area.change-password-area {
  width: unset;
  padding: 50px;
}

.change-password-area {
  border-radius: 50px;
  padding: 50px 70px;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border: 1px solid #1A003D;
}

.change-password-area h2 {
  color: #1a003d;
  font-size: 55px;
  font-weight: 900;
  margin-bottom: 30px;
  text-align: center;
}

.change-password-area label {
  color: #1a003d !important;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 3px;
}

.change-password-area .form-control {
  border: 1px solid #00000017 !important;
  padding: 12px;
  color: #000 !important;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 50px;
  -webkit-text-fill-color: #000 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
  width: 100% !important;
}

.change-password-area button {
  color: #fff;
  padding: 10px 45px;
  font-weight: 600;
  font-size: 17px;
  width: 35%;
  margin: 0 auto;
  display: block;
  margin-top: 42px;
  border-radius: 10px;
  background: var(--dark-blue, #1A003D) !important;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

button.close-btn-bottom {
  background: #808080cc !important;
  border: 1px solid #808080cc !important;
}

button.close-btn-bottom:hover {
  background: #808080cc !important;
  border: 1px solid #808080cc !important;
}

.dropdown-icon-area i {
  position: absolute;
  right: 13px;
  top: 13px;
  color: #bbbbbb;
  font-size: 20px;
}

.dropdown-icon-area {
  position: relative;
}

.dropdown-icon-area i.fa.fa-calendar-o {
  font-size: 13px;
}

td.question-area {
  white-space: unset;
}

.logo-mob {
  display: none;
}

td.contact-msg {
  width: 40%;
  white-space: break-spaces;
}

td.contact-msg a {
  padding-left: 8px;
  font-weight: 600;
  font-style: italic;
}

.message-content-popup.modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.message-content-popup.modal .modal-footer {
  border-top: none;
  justify-content: center;
  padding-top: 0;
}

.message-content-popup.modal .modal-footer button {
  margin-bottom: 0;
}

.message-content-popup.modal .modal-title.h4 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
}

.message-content-popup.modal .modal-body {
  font-size: 14px;
  color: #00000080;
  font-weight: 600;
  line-height: 23px;
}

.admin-balance {
  color: #000;
  font-weight: 800;
}

.admin-balance i {
  margin: 0 7px 0 2px !important;
  font-size: 12px;
  cursor: pointer;
}

.admin-balance img {
  width: 20px;
  margin-right: 2px;
}

.admin-balance img {
  width: 20px;
}

.header-top-wallet-area {
  display: flex;
  align-items: center;
}

.admin-balance p {
  margin-bottom: 0;
}

.admin-balance {
  background: #1a003d;
  border-radius: 6px;
  padding: 5px 10px;
  border: 1px solid #1a003d;
  margin: 3px;
}

.top-wallet-btn {
  display: flex;
}

.admin-balance p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.admin-balance.admin-address p {
  color: #1a003d;
  font-weight: 700;
}

.admin-balance p span {
  margin-left: 4px;
  font-weight: 700;
}

.admin-balance.admin-address {
  background: none;
  border: 2px solid #1a003d;
}

.admin-balance p span i.fa.fa-refresh {
  font-size: 16px;
}

i.fa.fa-refresh.animate {
  transform: rotate(90deg);
}

.logo-top img {
  width: 80px;
}

.sidebar .logo img {
  width: 70px;
  min-width: 70px;
}

.logo-mob img {
  width: 45px;
  min-width: 45px;
}

.commission-area h6 {
  font-size: 15px;
  font-weight: 600;
  color: #1a003d;
  margin-bottom: 5px;
}

.comission-inner-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 4px 0;
}

.comission-wallet-address {
  width: 100%;
}

.percentage-area {
  margin: 0 5px;
}

.comission-inner-area button {
  background: #1a003d !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
}

.comission-inner-area button {
  background: #1a003d;
  color: #fff !important;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 14px;
  margin: 0 0 6px 6px;
}

.comission-inner-area i.fa.fa-trash {
  color: red;
  font-size: 18px;
  align-items: center;
  margin: 0 0 12px 5px;
  cursor: pointer;
}

.comission-inner-area.wallet-address-bottom label.form-label {
  font-size: 15px !important;
}

.comission-inner-area label {
  font-size: 13px !important;
}

.comission-outer-area {
  max-height: 140px;
  overflow: auto;
  padding-right: 3px;
}

.comission-inner-area.commission-label-area .comission-wallet-address {
  width: 59%;
}

.comission-inner-area.commission-label-area {
  justify-content: flex-start;
}

.commission-area {
  margin-bottom: 10px;
}

.who-cant-bet-area {
  display: flex;
  align-items: center;
}

.who-cant-bet-area input {
  height: 10px;
  max-height: 10px;
  margin-right: 5px;
  padding: 9px;
}

.who-cant-bet-area .form-check {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.who-cant-bet-area label {
  font-size: 15px;
  font-weight: 500;
}

.form-check-input:focus {
  box-shadow: unset !important;
}

p.wallet-address-add {
  margin-bottom: 0;
  text-align: end;
  font-size: 14px;
  color: #1a003d;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}

.risk-free-batch {
  position: absolute;
  left: -27px;
  top: 9px;
  width: -moz-fit-content;
  width: fit-content;
}

.risk-free-batch p {
  align-items: center;
  background: #fc3c74;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 25px;
  justify-content: center;
  padding: 0 25px;
  transform: rotate(316deg);
}

p.wallet-address-add i {
  font-size: 12px;
  margin-right: 1px;
}

.selling-option-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.selling-option-top h6 {
  margin-bottom: 0;
  font-size: 15px;
  color: #1a003d;
  font-weight: 600;
  width: 165px;
}

.selling-option-top .form-switch .form-check-input {
  background-repeat: no-repeat;
}

.selling-option-top .form-switch .form-check-input {
  background-color: #fff;
  border: 1px solid #00000033 !important;
  width: 34px !important;
  height: 13px;
  padding: 8px;
}

.selling-option-top .form-switch {
  margin: 0 6px 0 4px;
}

.filter-top-area.select-prediction .css-b62m3t-container {
  width: 180px;
}

span.text-danger {
  font-size: 14px;
}

.paid-risk-options input#default-radio {
  border: 1px solid #9a9a9a9c !important;
  padding: 0;
  color: #000 !important;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 15px;
  -webkit-text-fill-color: unset !important;
  border-radius: 50% !important;
  width: 15px;
  margin-right: 4px;
}

.paid-risk-options .form-check label {
  font-size: 14px;
  cursor: pointer;
}

.paid-risk-options .form-check-input:checked {
  background-color: #1a003d !important;
}

.paid-risk-options input[type="radio"]:checked::before {
  position: absolute;
  color: #fff !important;
  content: "" !important;
  font-weight: bolder;
  font-size: 17px;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.third-option i {
  color: red;
  margin: 0 0 0 5px;
}

.paid-risk-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.paid-risk-options .form-check {
  display: flex;
  align-items: center;
  margin: 0 20px 2px 0;
  cursor: pointer;
}

.paid-risk-options input {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important; 
  max-width: 16px;
  max-height: 16px;
  padding: 0;
  border: 1px solid #1a003d2e !important;
  cursor: pointer;
}

.progess-total-right-area {
  padding-left: 15px;
  max-height: 160px;
  overflow: auto;
}
.progess-total-right-area li {
  color: #a550d8;
  list-style: disc;
  margin: 10px 45px 5px 0;
  font-size: 15px;
}
button.launch-btn.btn.btn-unset {
  background: #fff !important;
  color: #1a003d !important;
  padding: 2px 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}
.heading-top-area-right button {
  background: #1a003d !important;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 7px;
  margin: 0 8px 0 0;
}
.poll-prizes-ara.detail-side-poll-prize {
  position: absolute;
  z-index: 1;
  right: 95px;
  top: -12px;
}

.poll-prizes-ara.detail-side-poll-prize img {
  width: 20px;
  min-width: 20px;
}
.scrollable-div-options {
  max-height: 200px;
  overflow: auto;
  margin: 10px 0 20px;
}
.heading-top-area-right.bets-won-filter .css-b62m3t-container {
  width: 200px;
}
i.fa.fa-thumb-tack {
  transform: rotate(50deg);
}
.heading-top-area-right.bets-won-filter .filter-top-area {
  display: flex;
  align-items: center;
}
.date-picker {
  display: grid;
  margin-right: 10px;
}
.date-picker input {
  border: 1px solid hsl(0, 0%, 80%);
  padding: 6px;
  border-radius: 5px;
}
.search-area input {
  padding: 9px;
  color: #000;
  border: 1px solid #00000029;
  border-radius: 10px;
  width: 200px;
  min-width: 200px;
  background: #fff;
  margin-right: 8px;
}
.search-area.redem-area-search input::placeholder{
  font-size: 14px;
}
.search-area.redem-area-search select {
  padding: 9px;
  color: #000;
  border: 1px solid #00000029;
  border-radius: 10px;
  background: #fff;
  width: 150px;
  min-width: 150px;
}
.search-area.redem-area-search {
  display: flex;
  align-items: center;
}
.redem-area-input, .redem-area-select  {
  position: relative;
}
.redem-area-input i{
  position: absolute !important;
  right: 20px !important;
  bottom: 14px !important;
  top: unset !important;
}
a.item-on-dropdown.dropdown-item, a.item-on-dropdown.dropdown-item:hover {
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 0px 12px;
}
.dropdown-menu.show {
  display: block;
  min-width: fit-content;
}
.redem-area-select i {
  position: absolute !important;
  right: 15px !important;
  bottom: 12px !important;
  top: unset !important;
  font-size: 21px;
}
.search-area input {
  padding: 9px 30px 9px 9px;
  color: #000;
  border: 1px solid #00000029;
  border-radius: 10px;
  width: 220px;
  min-width: 220px;
  background: #fff;
  margin-right: 8px;
}
.bets-won-right-area {
  display: flex;
  align-items: end;
}
.usdc-balance {
  background: #1A003D;
  font-size: 15px;
  font-weight: 700;
  border: 2px solid #1A003D;
  color: #fff;
  padding: 6px 13px;
  border-radius: 5px;
  margin-right: 10px;
}
.folio-balance {
  border: 2px solid #1A003D;
  font-size: 15px;
  font-weight: 700;
  background: none !important;
  margin-right: 13px;
  padding: 6px 13px;
  border-radius: 5px;
}
.line-chart-graph {
  background: #fff;
  border: 1px solid #80808042;
  border-radius: 19px;
  padding: 18px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (max-width: 1600px) {
  .system-log-area-date-picker input {
    width: 120px;
  }
  .card-content h2 {
    font-size: 21px;
}
.card-img-view img {
  width: 55px;
  max-width: 55px;
}

  p.detail-user-name {
    margin: 0 0 0 12px;
  }

  a.detail-user-name p {
    margin-bottom: 0;
    margin-left: 12px;
  }

  .admin-header,
  .footer-content {
    margin-left: 0;
  }

  .audit-buttons.inner-table button {
    width: 100%;
    margin-right: 0 !important;
    font-size: 11px !important;
  }

  .description-box {
    min-width: 158px;
  }

  .icons-actions .btn {
    width: 100%;
  }

  .detail-area-two .user-info p span {
    font-size: 11px;
  }

  .detail-area-two .user-info p {
    font-size: 12px;
  }

  .custom_height {
    min-height: 680px;
  }

  .mian_content.active .footer-content {
    margin-left: 245px;
  }

  .detail-area-two .user-info p {
    padding: 10px 0px 0 14px;
  }

  .dashboad-content {
    padding: 20px;
  }

  .mian_content.active .admin-header {
    margin-left: 230px;
  }

  .dashboard-left h3 {
    font-size: 22px;
  }

  .dashboard-right img {
    width: 40px;
    height: 40px;
  }

  td {
    font-size: 12px;
    white-space: nowrap;
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .filter-data .css-b62m3t-container {
    width: 100px;
  }

  .user-info p,
  .user-info p span {
    font-size: 14px;
  }

  .user-info p span i {
    font-size: 14px;
    margin-left: 5px;
  }

  .user-profile img {
    width: 75px;
    height: 75px;
  }

  .sidebar {
    width: 244px !important;
    padding: 0 15px;
  }

  .sidebar_option {
    font-size: 14px;
    padding: 13px 0;
  }

  .mian_content.active .content {
    padding-left: 235px;
  }

  .mian_content.active .barbtn {
    padding-left: 230px;
  }
}


/*------------------------------*/

.user_detail {
  color: #6B24D6;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
}

.user_detailField {
  margin-bottom: 2%;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  font-weight: 600;
}

.categoryBtn {
  background-color: #6B24D6 !important;
  border: 1px solid #6B24D6 !important;
  border-radius: 25px !important;
}

.cat_Edit {
  width: 80px;
  padding: 3px !important;
  font-size: 14px !important;
}

.cat_Delete {
  width: 80px;
  font-size: 14px !important;
  padding: 3px !important;
  margin-left: 5px;
  background: red !important;
}

.subcategoryBtn {
  background-color: #6B24D6 !important;
  border: 1px solid #6B24D6 !important;
}

.sub_Edit {
  width: 80px;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

.sub_Delete {
  width: 80px;
  margin-left: 5px;
  background: red !important;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

/* .RecentUserList{
  background-color: pink !important;
}
.RecentUserList thead{
  background-color: pink !important;
}
.RecentUserList thead tr{
  background-color: pink !important;
} */
.view-eye {
  color: black !important;
}

.RecentUserList {
  color: #000 !important;
}

.RecentUserList thead tr th {
  background-color: transparent !important;
  color: black !important;
  border-style: none !important;
}

.RecentUserList tbody tr td {
  background-color: transparent !important;
}

.RecentUserList tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.RecentUserList tbody tr td {
  color: #000 !important;
}


/*-----------------*/
.UserListTable {
  color: white !important;
}

.UserListTable thead tr th {
  background-color: transparent !important;
  color: #fff !important;
  border-style: none !important;
  font-size: 14px;
  font-weight: 500;
}

.option-area label.form-label {
  display: flex;
  align-items: center;
}

.third-option {
  display: flex;
  align-items: center;
}

.option-area label i {
  color: red;
  font-size: 16px;
}

.UserListTable tbody tr td {
  background-color: transparent !important;
}

.UserListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.UserListTable tbody tr td {
  color: #fff !important;
  white-space: nowrap;
}

/*-------------------*/

.categoryTable {
  color: #000 !important;
}

.categoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.categoryTable tbody tr td {
  background-color: transparent !important;
}

.categoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.categoryTable tbody tr td {
  color: #000 !important;
}


/*----------------------*/

.subcategoryTable {
  color: white !important;
}

.subcategoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.subcategoryTable tbody tr td {
  background-color: transparent !important;
}

.subcategoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.subcategoryTable tbody tr td {
  color: #000 !important;
}

/*-------------------------*/
.productListTable {
  color: #000 !important;
}

.productListTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

img.rounded-circle {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #1A003D;
  height: 50px;
}

.user-detail-profile img {
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #1A003D;
  height: 120px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #1a003d !important;
}

.change-section {
  min-height: 780px;
  margin-top: 60px;
}

.productListTable tbody tr td {
  background-color: transparent !important;
}

.productListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.productListTable tbody tr td {
  color: #000 !important;
}



/*---------------product detail-----------------*/

.product-detail {
  border: 1px solid white;
  border-radius: 5px;
}

.product-image-left img {
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  border-radius: 26px;
  border: 1px solid #23232330;
}

.product-detail-right h6 {
  font-size: 20px;
  text-transform: uppercase;
  color: #000 !important;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h3 {
  color: #000 !important;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h4 span {
  font-size: 15px;
}

.product-detail-right h4 {
  color: #1A003D;
  font-weight: 800;
  margin-bottom: 20px;
}

.product-detail-right p span {
  font-weight: 700;
  color: #1A003D;
  margin-right: 40px;
}

.product-detail-right p {
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 20px;
}

/* 
.product-detail-right {
  padding: 30px 0 0;
} */



.product-availability-order p span {
  color: #1A003D !important;
  font-weight: 700 !important;
  margin-right: 0 !important;
}

.product-availability-order p {
  font-size: 18px;
  color: #000 !important;
  margin-right: 30px;
  margin-bottom: 0 !important;
}

.product-availability-order {
  display: flex;
  margin-bottom: 20px;
}


.product-multiple-images {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 48px;
}


.product-images-area img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

.product-images-area {
  width: 120px;
  height: 120px;
  background: #80808061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  position: relative;
}

.product-images img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 26px;
}

.product-images {
  margin-right: 10px;
  margin-top: 20px;
}

.product-images img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid #23232330;
}

.product-detail.row {
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border: 1px solid #1A003D;
  border-radius: 20px;
  padding: 25px;
}

.product-bottom.row {
  color: #000;
  padding: 0 15px;
}

.product-bottom.row h6 {
  font-weight: 600;
  color: #000;
}

.product-bottom.row p {
  color: #1A003D;
  font-size: 15px;
  font-weight: 600;
}

.order-detail-right-area {
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  background: #fff;
  border: 1px solid #1A003D;
  border-radius: 20px;
  overflow: hidden;
  color: #000;
  padding: 15px;
}

.retailer-info h4 {
  font-size: 18px;
  border-bottom: 1px solid #0000000f;
  padding-bottom: 8px;
}

img.reatiler-image {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #9999997a;
}

a.detail-user-name p {
  margin-bottom: 0;
}

a.detail-user-name,
.detail-user-name a {
  color: #000;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
}

.order-detail-retailer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #00000014;
  padding: 10px 0;
}

.retailer-info {
  background: #fbdfce96;
  padding: 10px;
  border-radius: 10px;
}

.order-detail-retailer-info h6 {
  margin-bottom: 0;
}

.order-detail-retailer-info span {
  color: #8b8b8b;
  font-size: 15px;
}

.add-btn {
  margin-top: 20px;
}

.export-csv-btn button {
  background: #1A003D;
  color: #fff;
  border-radius: 10px;
  padding: 7px 16px;
  font-weight: 400;
  margin-left: 7px;
}

.keynote {
  padding: 20px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border: 1px solid #00000026;
  border-radius: 18px;
  margin-bottom: 7px;
}

.dashboard-cards {
  background: url('../images/bg-cards.svg') no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
}

button.btn.btn-success.reschedule-btn {
  background: #12225c;
}

button.btn.btn-success.confirm-btn {
  background: #067b18;
  margin-left: 7px;
}

tbody td,
tbody th {
  min-width: fit-content !important;
  max-width: fit-content !important;
}

.action-btn-box {
  min-width: 113px;
}

.call-key-text ul {
  padding: 5px;
  list-style: initial;
  margin-left: 19px;
}

.password-area {
  position: relative;
}

.password-area i {
  position: absolute;
  color: #1a003d;
  right: 12px;
  top: 16px;
  font-size: 15px;
}

.export-csv-btn.add-btn-area button i {
  font-size: 14px;
  margin-right: 3px;
}

.export-csv-btn.add-btn-area button {
  font-weight: 600;
}

.sidebar-toggle-btn {
  display: block;
  position: absolute;
  width: 40px;
  top: 85px;
  left: 10px;
}

span.sub-head {
  font-style: italic;
  color: #0d001e;
  font-weight: 600;
  font-size: 13px;
}

button.login-btn-submit {
  width: fit-content !important;
}

ul.pagination {
  justify-content: end;
}

ul.pagination li a {
  color: #1a003d;
  font-weight: 600;
}

.pagination .active a {
  color: #fff !important;
  border: 1px solid;
  padding: 10px;
  background: #1a003d;
}

.audit-request-box .table-responsive-sm th {
  color: #fff;
}

.top-fund-wallet p {
  background: #1a003d;
  width: fit-content;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 9px 15px;
  border-radius: 10px;
}

img.usd-coin {
  width: 28px;
}

.card-content p i {
  color: #1a003d !important;
}

.card-content p {
  color: #1a003d;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.top-fund-wallet img {
  margin-right: 7px;
  width: 23px;
}

.top-fund-wallet p i {
  font-size: 12px;
  margin: 0 10px 0 7px !important;
}

.top-fund-wallet {
  margin: 0 10px 12px 0;
}

.failed-transation-status{
  background: #7f9cfc;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.failed-transation-status.pending{
  background: #7f9cfc;
  width: fit-content;
}
p.animate-charcter span {
  color: #dc3545;
  font-weight: 700;
}
.bonus-points p {
  margin: 0;
  font-size: 18px;
  color: #1a003d;
  font-weight: 600;
  line-height: 15px;
}
p.animate-charcter span {
  color: #dc3545;
  font-weight: 700;
  font-size: 14px;
}
.bonus-points {
  margin-right: 16px;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
          #231557 0%,
          #44107a 29%,
          #ff1361 67%,
          #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 190px;
}

@keyframes textclip {
  to {
      background-position: 200% center;
  }
}
.bonus-countdown span {
  font-size: 14px;
  color: #1a003d;
  font-weight: 600;
}
.bonus-countdown {
  line-height: 17px;
}
/* .search-area input:focus{
  background-color:transparent;
} */
a.download-csv i {
  margin-right: 3px;
}
a.download-csv {
  background: #1a003d !important;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 7px;
  margin: 0 8px 0 0;
  text-decoration: none;
}
@media (max-width: 1500px) {
  td.contact-msg {
    width: 100%;
  }

  .dashboard-main-area .form-select,
  .dashboard-main-area .form-control {
    width: 160px;
    min-width: 160px;
    background-color: #fff;
  }

  .dashboard-area-heading h2 {

    margin-bottom: 22px;
    font-size: 30px;

  }

  .card-img-view img {
    width: 65px;
    height: auto;
  }

  .card-content h3 {
    font-size: 17px;

  }

  .card-content {
    margin-left: 10px;
  }
}

@media (max-width: 1400px) {

  .barbtn {
    left: 19%;
  }

  .admin-header,
  .footer-content {
    min-width: 82%;
  }
}

@media (max-width: 1200px) {
  .login-content-area {
    padding: 50px;
  }

  .change-password-area {
    padding: 30px;
  }

  .mian_content.active .sidebar {
    left: -270px;
  }

  .mian_content .barbtn {
    padding-left: 240px;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .admin-header {
    margin-left: 0;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .content {
    padding-left: 0;
  }

  .mian_content.active .footer-content {
    margin-left: 0;
  }

  .mian_content .sidebar {
    left: 0;
  }
}

@media (max-width: 991px) {

  .main-home-top-area {
    margin-top: 60px;
  }

  .top-back-btn {
    margin-top: 40px;
  }

  .sidebar.sidebar-mobile {
    display: block !important;
    z-index: 1;
    box-shadow: 0 4px 20px #ffffff40;
    padding-top: 80px;
  }

  .sidebar-cross-btn {
    z-index: 2;
    position: absolute;
    width: 35px;
    top: 25px;
    left: 12px;
  }

  .sidebar-toggle-btn {
    display: block;
    position: absolute;
    width: 40px;
    top: 105px;
  }

  .dashboard-area-heading.management-heading {
    margin-top: 40px;
  }

  .logo-mob {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .dashboard-main-area {
    margin-left: 0;
  }

  .header-right {
    justify-content: space-between;
  }
}

@media (max-width: 650px) {
  .header-top-wallet-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: right;
  }

}

@media (max-width: 575px) {
  .top-wallet-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }

  .admin-balance p {
    font-size: 12px;
  }

  ul.prediction-tab.nav-tabs .nav-link {
    padding: 6px 6px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .filter-top-area.select-prediction .css-b62m3t-container {
    width: 110px;
  }

  .admin-balance {
    background: #1a003d;
    border-radius: 6px;
    padding: 2px 2px;
    border: 1px solid #1a003d;
    margin: 3px;
  }

  .sidebar-toggle-btn {
    display: block;
    position: absolute;
    width: 40px;
    top: 110px;
  }

  .admin-balance img {
    width: 15px;
  }

  .admin-balance i {
    font-size: 10px;
  }

  .admin-balance p {
    font-size: 11px;
  }

  .card-img-view img {
    width: 55px;
    height: auto;
  }

  .card-content h3 {
    font-size: 14px;
  }

  .card-content h2 {
    font-size: 40px;
    font-weight: 800;
    color: #1a003d;
    margin-top: 0;
  }

  .prediction-detail-right {
    padding: 0 10px 10px;
  }

  .swal-footer button {
    padding: 10px 22px;
  }

  .swal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .change-password-area {
    padding: 20px;
  }

  .heading-top-area {
    flex-wrap: wrap;
  }

  .chamge-text {
    font-size: 26px !important;
  }

  .swal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swal-modal {
    border-radius: 19px;
    width: 100% !important;
    margin: 0 10px !important;
    min-width: unset;
  }

  .login-content-area {
    padding: 20px;
  }

  .prediction-detail-right-top-heading {
    flex-wrap: wrap;
  }

  .prediction-detail-right-top-heading h6 {
    font-size: 22px;
  }

  .login-content-area h2 {
    font-size: 35px;

  }

  .start-end-date p {
    font-size: 12px;
  }

  .bg-main-area {
    padding: 15px 0;
  }

  .prediction-detail-left-content h5 {
    font-size: 20px;
  }

  .login-content-area p {
    font-size: 14px;
    color: #000;
    text-align: center;
  }

  .dashboard-area-heading.management-heading {
    margin-top: 60px;
  }

  .export-csv-btn button {
    margin-left: 0;
  }
}

@media (max-width: 420px) {
  .sidebar-toggle-btn {
    display: block;
    position: absolute;
    width: 40px;
    top: 118px;
  }

}

@media (max-width: 380px) {
  .sidebar-toggle-btn {
    display: block;
    position: absolute;
    width: 40px;
    top: 140px;
  }
}